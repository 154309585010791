import React from 'react'

import { Navbar, Nav } from "react-bootstrap";

import logo from "../assets/img/brand.png";

function Header() {
    return (
        <header>
            <Navbar bg="info" expand="lg" variant="dark" fixed="top">
            <Navbar.Brand href="#home"><img src={logo} /></Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav fill variant="pills" defaultActiveKey="#home" className="mr-auto">
                    <Nav.Link href="#home">Principal</Nav.Link>
                    <Nav.Link href="#services">Serviços</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            <Navbar.Text>
            <a target="_blank" href=" https://wa.me/5541999471740?text=Gostaria%20de%20agendar%20um%20hor%C3%A1rio"><i className="fab fa-whatsapp"></i> +55 41 9 9947 1740</a>
            </Navbar.Text>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Navbar>
        </header>
    )
}

export default Header
