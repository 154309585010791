import React from 'react';

//Carregar Componentes
import JLPServicesCarousel from './JLPServicesCarousel';
import JLPServicesCard from './JLPServicesCard';

//Folha(s) Estilo(s)
import './JLPServices.css';

//Renderização
function JLPServices() {
    return (
		<section id="services" className="pt-1 pb-5">
			<div style={{ maxWidth: 1000, marginLeft: 'auto', marginRight: 'auto', marginTop: 64 }}>
				<JLPServicesCarousel show={2} infiniteLoop={false}>
					{JLPServicesCard}
				</JLPServicesCarousel>
			</div>
		</section>
    )
}

export default JLPServices;
