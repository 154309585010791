import despacho_aduaneiro from "./assets/img/despacho-aduaneiro.jpg";
import exportacao_temporaria from "./assets/img/exportacao-temporaria.jpg";
import classificacao_fiscal from "./assets/img/classificacao-fiscal.jpg";
import consultoria_aduaneira from "./assets/img/consultoria-aduaneira.jpg";
import drawback from "./assets/img/drawback.jpg";

const services = [
    {
        _id : '1',
        'name' : 'Despacho Aduaneiro',
        'image' : despacho_aduaneiro,
        'description' : 'Desembaraço Aduaneiro na importação e exportação em todos os modais, portos, aeroportos, fronteiras e Portos Secos.',
        
    },
    {
        _id : '2',
        'name' : 'Exportação Temporária',
        'image' : exportacao_temporaria,
        'description' : 'Orientar os exportadores e demais intervenientes sobre os procedimentos relativos ao Regime Aduaneiro Especial de Exportação Temporária para fins de conserto de ativos.',
        
    },
    {
        _id : '3',
        'name' : 'Classificação Fiscal',
        'image' : classificacao_fiscal,
        'description' : 'Classificação tarifária para determinar ou revisar a carga tributária dos produtos.',
        
    },
    {
        _id : '4',
        'name' : 'Consultoria Aduaneira',
        'image' : consultoria_aduaneira,
        'description' : 'Consultoria para atender os trâmites legais das operações de comércio exterior, fiscal e logistica.',
        
    },
    {
        _id : '5',
        'name' : 'Drawback',
        'image' : drawback,
        'description' : 'Benefícios de suspensão ou isenção de tributos na importação de insumo através de exportação de produtos industrializados.',
        
    }
]

export default services;