import './App.css';

import JLPJumbox from './assets/img/jumbox-brand.png';

import { Container, Jumbotron, Image } from "react-bootstrap";

import Header from './components/Header';
import JLPServices from './components/JLPServices';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <Header />
        <Container fluid>
          <Jumbotron id="home">
            <Image src={JLPJumbox} fluid />
            <p>Foque nos seus objetivos. Deixe que cuidemos da Aduana.</p>
          </Jumbotron>
          <JLPServices />
          <Footer />
        </Container>
    </div>
  );
}

export default App;
