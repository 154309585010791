import React from 'react'

import './Footer.css';

import {  } from "react-bootstrap";

function Footer() {
    return (
        <footer>
            <p>Copyright &copy; JLP Brasil</p>
            <p><a href="mailto:contato@jlpbrasil.com.br">contato@jlpbrasil.com.br</a></p>
        </footer>
    )
}

export default Footer
