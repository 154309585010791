//
// https://dev.to/rakumairu/simple-react-carousel-24m0
//
import React, { useState, useEffect } from 'react';
import { Row } from "react-bootstrap";

//Carregar estilos
import './JLPServicesCarousel.css'

const JLPServicesCarousel = (props) => {

    //Processo lógico
    const {children, show, infiniteLoop} = props
    const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? show : 0)
    const [length, setLength] = useState(children.length)
    const [isRepeating, setIsRepeating] = useState(infiniteLoop && children.length > show)
    const [transitionEnabled, setTransitionEnabled] = useState(true)
    const [touchPosition, setTouchPosition] = useState(null)

    useEffect(() => {
        setLength(children.length)
        setIsRepeating(infiniteLoop && children.length > show)
    }, [children, infiniteLoop, show])

    useEffect(() => {
        if (isRepeating) {
            if (currentIndex === show || currentIndex === length) {
                setTransitionEnabled(true)
            }
        }
    }, [currentIndex, isRepeating, show, length])

    const next = () => {
        if (isRepeating || currentIndex < (length - show)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }
    
    const prev = () => {
        if (isRepeating || currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition
    
        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    const handleTransitionEnd = () => {
        if (isRepeating) {
            if (currentIndex === 0) {
                setTransitionEnabled(false)
                setCurrentIndex(length)
            } else if (currentIndex === length + show) {
                setTransitionEnabled(false)
                setCurrentIndex(show)
            }
        }
    }

    const renderExtraPrev = () => {
        let output = []
        for (let index = 0; index < show; index++) {
            output.push(children[length - 1 - index])
        }
        output.reverse()
        return output
    }

    const renderExtraNext = () => {
        let output = []
        for (let index = 0; index < show; index++) {
            output.push(children[index])
        }
        return output
    }

    //Renderização
    return(
        <div className="carousel-container">
            <Row>
                <div className="col-6">
                <h2 className="mb-3"><strong>Serviços</strong></h2>
                </div>
                <div className="col-6 text-right">
                    {
                        (isRepeating || currentIndex > 0) > 0 &&
                        <button className="left-arrow btn btn-outline-primary mb-3 mr-1" onClick={prev}>
                            <i className="fa fa-arrow-left"></i>
                        </button>
                    }
                    {
                        (isRepeating || currentIndex < (length - show)) &&
                        <button className="right-arrow btn btn-outline-primary mb-3 " onClick={next}>
                            <i className="fa fa-arrow-right"></i>
                        </button>
                    }
                </div>
            </Row>
            <div className="carousel-wrapper">
                <div 
                    className="carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`carousel-content show-${show}`}
                        style={{
                            transform: `translateX(-${currentIndex * (100 / show)}%)`,
                            transition: !transitionEnabled ? 'none' : undefined,
                        }}
                        onTransitionEnd={() => handleTransitionEnd()}
                    >
                        {
                            (length > show && isRepeating) &&
                            renderExtraPrev()
                        }
                        {children}
                        {
                            (length > show && isRepeating) &&
                            renderExtraNext()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JLPServicesCarousel