//=====================================================
//https://www.youtube.com/watch?v=R5O5lLIph9Y
//
//https://www.npmjs.com/package/react-elastic-carousel
//https://styled-components.com/
//=====================================================

import React from 'react'
import services from '../ServicesList';

//Folha(s) Estilo(s)
import './JLPServicesCard.css';

const JLPServicesCard = 
    services.map(service => (

            <div key={service._id} className="card" >
                <img src={service.image} alt={service.name} className="card-img-top rounded" />
                <div className="card-body">
                    <h5 className="card-title">{service.name}</h5>
                    <p className="card-text">{service.description}</p>
                </div>
            </div>

    ))

export default JLPServicesCard